@tailwind base;
@tailwind components;
@tailwind utilities;

/* Mobile frame styling corrections */
.marvel-device.note8 .sensors {
  display: none;
}

.marvel-device.note8 .more-sensors {
  display: none;
}

.marvel-device.note8 .shadow {
  box-shadow: inset 0 0 30px 0 rgba(255, 255, 255, 0.5), 0 0 20px 0 #fff,
    0 0 20px 0 rgba(255, 255, 255, 0.5) !important;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.Mui-selected {
  color: white;
}

/* import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
 */
